import { useState } from 'react'

import { GAME_TYPE } from 'enums/GameTypeEnum'

import { DressUpPromptResponse } from './ideaModels'

type Conversation = {
	content: string
	role: string
}

export function DressUpModel() {
	const [authorIdeation, setAuthorIdeation] = useState<string>('')
	const [companyProblem, setCompanyProblem] = useState<string>('')
	const [gameId, setGameId] = useState<string | null>(null)
	const [innovationCompany, setInnovationCompany] = useState<string>('')
	const [innovationTopic, setInnovationTopic] = useState<string>('')
	const [promptConversation, setPromptConversation] = useState<
		Conversation[]
	>([])
	const [responseConversation, setResponseConversation] = useState<
		Conversation[][]
	>([[], [], [], []])
	const [termsAgreed, setTermsAgreed] = useState(false)

	const gameTypeId = GAME_TYPE.DRESS_UP.value

	const [dressUpPrompt, setDressUpPropt] = useState<DressUpPromptResponse>()
	const setPromptResponse = (response: DressUpPromptResponse) => {
		setDressUpPropt(response)
	}

	const generateScenarioDisabled = () => {
		return innovationTopic.length === 0 || innovationCompany.length === 0
	}

	const generateNewScenarioDisabled = () => {
		return generateScenarioDisabled() || !dressUpPrompt
	}

	const submitIdeasDisabled = () => {
		if (
			generateScenarioDisabled() ||
			!authorIdeation ||
			(gameId && !termsAgreed)
		)
			return true
		return false
	}

	const promptToJSON = () => {
		return {
			innovationTopic,
			innovationCompany,
			companyProblem,
		}
	}

	const resetSoft = () => {
		setDressUpPropt(undefined)
		setResponseConversation([[], [], [], []])
		setAuthorIdeation('')
	}

	const reset = () => {
		setPromptConversation([])
		resetSoft()
	}

	const gameToJSON = () => {
		return {
			gameId: gameId !== null ? gameId : undefined,
			gameTypeId,
			innovationTopic,
			innovationCompany,
			innovationTopicManual: companyProblem,
			authorIdeation,
		}
	}

	return {
		innovationCompany,
		setInnovationCompany,
		companyProblem,
		setCompanyProblem,
		promptConversation,
		setPromptConversation,
		innovationTopic,
		setInnovationTopic,
		setPromptResponse,
		authorIdeation,
		setAuthorIdeation,
		responseConversation,
		setResponseConversation,
		generateScenarioDisabled,
		generateNewScenarioDisabled,
		promptToJSON,
		resetSoft,
		submitIdeasDisabled,
		gameToJSON,
		reset,
		gameId,
		setGameId,
		termsAgreed,
		setTermsAgreed,
		dressUpPrompt,
	}
}
