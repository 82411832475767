import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Button, Grid, InputAdornment, Typography } from '@mui/material'

import { BaseTextField } from 'components/Form'
import TopicChips from 'components/Hero/TopicChips'

import { palette } from 'theme/palette'

import { TopicType } from 'enums/TopicTypeEnum'

import { styles } from './Section1.styles'

export const TEST_ID = 'musical-chairs-section1'

export type Props = {
	companyProblem: string
	createPrompt: () => void
	editable: boolean
	generateScenarioDisabled: () => boolean
	innovationCompany: string
	innovationTopic: TopicType
	isLoading: boolean
	resetAllField: () => void
	setCompanyProblem: (val: string) => void
	setInnovationCompany: (val: string) => void
	setInnovationTopic: (val: TopicType) => void
} & React.HTMLAttributes<HTMLDivElement>

const Section1 = ({
	innovationCompany,
	setInnovationCompany,
	innovationTopic,
	setInnovationTopic,
	companyProblem,
	setCompanyProblem,
	generateScenarioDisabled,
	resetAllField,
	isLoading,
	createPrompt,
	editable,
	...props
}: Props) => {
	return (
		<Grid container {...props}>
			<Grid item xs={12}>
				<Typography variant="customTitle">
					1. INNOVATION TOPIC
				</Typography>
			</Grid>
			<Grid container>
				<Grid item sx={styles.sideBar} />
				<Grid
					item
					xs
					container
					direction="column"
					sx={styles.contentContainer}
				>
					<Grid item>
						<Typography
							data-testid={`${TEST_ID}__company-question`}
							color="black"
						>
							What company are you brainstorming about today?{' '}
							<span style={{ color: palette?.asterisk?.main }}>
								*
							</span>
						</Typography>
					</Grid>
					<Grid item>
						<BaseTextField
							disabled={!editable}
							InputLabelProps={{
								style: styles.inputLabel,
								required: false,
							}}
							variant="outlined"
							data-testid={`${TEST_ID}__company-name-input`}
							label="Enter company name here."
							value={innovationCompany}
							onChangeValue={(val) => setInnovationCompany(val)}
							required
							size="medium"
							InputProps={{
								endAdornment: !editable && (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
							sx={{
								'& .MuiInputBase-input': {
									boxSizing: 'content-box',
									height: '100%',
								},
								...styles.textField,
							}}
						/>
					</Grid>
					<Grid item>
						<Typography
							data-testid={`${TEST_ID}__topic-question`}
							color="black"
						>
							What business topic do you want to focus on?{' '}
							<span style={{ color: palette?.asterisk?.main }}>
								*
							</span>
						</Typography>
					</Grid>
					<Grid item>
						<TopicChips
							color={styles.chip.color}
							innovationTopic={innovationTopic}
							setInnovationTopic={setInnovationTopic}
							editable={editable}
						/>
					</Grid>
					<Grid item>
						<Typography
							data-testid={`${TEST_ID}__specific-question`}
							color="black"
							sx={{ opacity: '87%' }}
						>
							Optional: Would you like to focus on a specific part
							of your chosen business function?
						</Typography>
					</Grid>
					<Grid item>
						<BaseTextField
							disabled={!editable}
							InputLabelProps={{
								style: styles.inputLabel,
							}}
							variant="outlined"
							data-testid={`${TEST_ID}__company-problem-input`}
							label="Summarize in 5-10 words"
							onChangeValue={(val) => setCompanyProblem(val)}
							value={companyProblem}
							size="medium"
							InputProps={{
								endAdornment: !editable && (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
							sx={{
								'& .MuiInputBase-input': {
									boxSizing: 'content-box',
									height: '100%',
								},
								...styles.textField,
							}}
						/>
					</Grid>
					<Grid
						item
						container
						spacing={4}
						sx={{ flexDirection: { sm: 'row' } }}
					>
						<Grid item>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__create-prompt-button`}
								disabled={
									generateScenarioDisabled() || isLoading
								}
								onClick={createPrompt}
							>
								START IDEATING
							</Button>
						</Grid>
						<Grid item>
							<Button
								disabled={!editable}
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__reset-all-button`}
								onClick={resetAllField}
							>
								RESET ALL FIELDS
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Section1
