import React from 'react'

import LockIcon from '@mui/icons-material/Lock'
import { Box, Button, InputAdornment, Typography } from '@mui/material'

import BusinessTopicChips from 'components/BusinessTopicChips'
import { BaseTextField } from 'components/Form'

import { palette } from 'theme/palette'

import { BusinessTopicType } from 'enums/TopicTypeEnum'

import { styles } from './InnovationSection.styles'

export const TEST_ID = 'battle-ships-innovation-section'

export type Props = {
	companyName: string
	companyRisk: string
	createPrompt: () => void
	editable: boolean
	generateScenarioDisabled: () => boolean
	isLoading: boolean
	resetAll: () => void
	setCompanyName: (value: string) => void
	setCompanyRisk: (value: string) => void
	setTopic: (val: BusinessTopicType) => void
	topic: BusinessTopicType
}

const InnovationSection = ({
	topic,
	companyName,
	companyRisk,
	setCompanyRisk,
	setCompanyName,
	setTopic,
	generateScenarioDisabled,
	resetAll,
	isLoading,
	createPrompt,
	editable,
}: Props) => {
	return (
		<>
			<Typography marginY={'2rem'} variant={'overline'}>
				1. INNOVATION TOPIC
			</Typography>
			<Box display={'flex'}>
				<Box marginRight={'2rem'} sx={styles.sideBar} />
				<Box
					display={'flex'}
					flexDirection={'column'}
					rowGap={'3rem'}
					flexGrow="1"
				>
					<Box
						display={'flex'}
						flexDirection={'column'}
						rowGap={'1rem'}
						flexGrow="1"
					>
						<Typography color="black">
							What company are you brainstorming about today?{' '}
							<span
								style={{
									color: palette.asterisk.main,
								}}
							>
								*
							</span>
						</Typography>
						<BaseTextField
							disabled={!editable}
							InputLabelProps={{
								style: styles.inputLabel,
								required: false,
							}}
							variant="outlined"
							data-testid={`${TEST_ID}__company-name-input`}
							label="Enter company name here."
							value={companyName}
							onChangeValue={(val) => setCompanyName(val)}
							required
							size="medium"
							InputProps={{
								endAdornment: !editable && (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
							sx={{
								'& .MuiInputBase-input': {
									boxSizing: 'content-box',
									height: '100%',
								},
								...styles.textField,
							}}
						/>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'column'}
						rowGap={'1rem'}
						flexGrow="1"
					>
						<Typography color="black">
							What business function do you want to focus on?{' '}
							<span
								style={{
									color: palette.asterisk.main,
								}}
							>
								*
							</span>
						</Typography>
						<BusinessTopicChips
							innovationTopic={topic}
							setInnovationTopic={setTopic}
							backgroundColor="#FFE0B2"
							editable={editable}
						/>
					</Box>
					<Box
						display={'flex'}
						flexDirection={'column'}
						rowGap={'1rem'}
						flexGrow="1"
					>
						<Typography color="black">
							Optional: Would you like to focus on a specific part
							of your chosen business function (within 5-10
							words)?
						</Typography>
						<BaseTextField
							disabled={!editable}
							InputLabelProps={{ style: styles.inputLabel }}
							variant="outlined"
							data-testid={`${TEST_ID}__company-risk-input`}
							label="Summarize in 5-10 words"
							value={companyRisk}
							onChangeValue={(val) => setCompanyRisk(val)}
							size="medium"
							InputProps={{
								endAdornment: !editable && (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								),
							}}
							sx={{
								'& .MuiInputBase-input': {
									boxSizing: 'content-box',
									height: '100%',
								},
								...styles.textField,
							}}
						/>
					</Box>
					<Box
						display="flex"
						sx={{
							flexDirection: {
								xs: 'column-reverse',
								sm: 'row',
							},
						}}
						justifyContent="flex"
						gap="2rem"
					>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__create-prompt-button`}
							disabled={generateScenarioDisabled() || isLoading}
							onClick={createPrompt}
						>
							START IDEATING
						</Button>
						<Button
							disabled={!editable}
							variant="text"
							color="secondary"
							data-testid={`${TEST_ID}__reset-all-button`}
							onClick={resetAll}
						>
							RESET ALL FIELDS
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	)
}

export default InnovationSection
