import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Typography } from '@mui/material'
import logger from 'utils/logger'

import { useAuth } from 'contexts/AuthProvider'
import { MODAL_TYPES, useModalContext } from 'contexts/ModalProvider'
import { useToastContext } from 'contexts/ToastProvider'

import { updateUser } from 'services/user.service'

import { BaseTextField } from 'components/Form'

import profileBannerImage from 'assets/images/ProfilePage/profileBannerImage.svg'

import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './Profile.styles'

export const TEST_ID = 'profile-page'

const ProfilePage = () => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [disableSaveButton, setDisableSaveButton] = useState(true)
	const { user } = useAuth()
	const { showModal } = useModalContext()
	const { showAlert } = useToastContext()

	const saveChanges = async () => {
		const data = {
			firstName,
			lastName,
		}
		try {
			await updateUser(data)

			if (showAlert) {
				showAlert({
					objectType: 'updateUser',
					severity: ToastSeverity.SUCCESS,
				})
			}
		} catch (e) {
			logger.error(e)
			setDisableSaveButton(false)
		}
	}

	const changePassword = useCallback(() => {
		showModal(MODAL_TYPES.FORGOT_PASSWORD_MODAL)
	}, [showModal])

	const deleteAccount = useCallback(() => {
		showModal(MODAL_TYPES.DELETE_ACCOUNT_MODAL)
	}, [showModal])

	useEffect(
		() => {
			setFirstName(user?.firstName ?? '')
			setLastName(user?.lastName ?? '')
			setEmail(user?.email ?? '')
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)
	return (
		<Box>
			<Box
				data-testid={`${TEST_ID}__banner-section`}
				sx={styles.bannerSection}
			>
				<Box
					component="img"
					alt="Profile Banner Image"
					data-testid={`${TEST_ID}__profile-banner-image`}
					src={profileBannerImage as unknown as string}
				/>

				<Box sx={styles.headerContainer}>
					<Typography sx={styles.header}>My Profile</Typography>
					<Typography sx={styles.subheader}>
						View and modify your personal details, account settings,
						and notification preferences.
					</Typography>
				</Box>
			</Box>

			<Box
				data-testid={`${TEST_ID}__profile-input-section`}
				sx={styles.profileInputSection}
			>
				<Box marginRight="2rem" sx={styles.sideBar} />
				<Box sx={styles.formContainer}>
					<Typography sx={styles.overline}>BASIC DETAILS</Typography>

					<Box sx={styles.nameInputContainer}>
						<Box sx={styles.nameInputRow}>
							<Typography sx={styles.label}>
								First Name
							</Typography>
							<BaseTextField
								InputLabelProps={{ style: styles.inputLabel }}
								variant="outlined"
								data-testid={`${TEST_ID}__first-name-input`}
								value={firstName}
								onChangeValue={(val) => {
									setFirstName(val)
									setDisableSaveButton(false)
								}}
								required
								size="medium"
								sx={styles.textField}
							/>
						</Box>

						<Box sx={styles.nameInputRow}>
							<Typography sx={styles.label}>Last Name</Typography>
							<BaseTextField
								InputLabelProps={{ style: styles.inputLabel }}
								variant="outlined"
								data-testid={`${TEST_ID}__last-name-input`}
								value={lastName}
								onChangeValue={(val) => {
									setLastName(val)
									setDisableSaveButton(false)
								}}
								required
								size="medium"
								sx={styles.textField}
							/>
						</Box>
					</Box>
					<Typography sx={styles.label}>Email</Typography>
					<BaseTextField
						InputLabelProps={{ style: styles.inputLabel }}
						variant="outlined"
						data-testid={`${TEST_ID}__email-input`}
						value={email}
						size="medium"
						sx={styles.textField}
						readOnly={true}
					/>
					<Button
						color="secondary"
						variant="contained"
						data-testid={`${TEST_ID}__save-changes-button`}
						disabled={disableSaveButton}
						onClick={() => {
							saveChanges()
							setDisableSaveButton(true)
						}}
						sx={styles.button}
					>
						SAVE CHANGES
					</Button>

					<Box sx={styles.buttonsContainer}>
						<Typography
							data-testid={`${TEST_ID}__account-heading`}
							sx={styles.overline}
						>
							ACCOUNT
						</Typography>

						<Typography
							data-testid={`${TEST_ID}__password-heading`}
							sx={styles.label}
						>
							Password
						</Typography>

						<Typography sx={styles.caption}>
							Change your brainstorm account password.
						</Typography>
						<Button
							color="secondary"
							variant="contained"
							data-testid={`${TEST_ID}__change-password-button`}
							onClick={changePassword}
							sx={styles.button}
						>
							CHANGE PASSWORD
						</Button>

						<Typography
							data-testid={`${TEST_ID}__delete-account-heading`}
							sx={styles.label}
						>
							Delete Account
						</Typography>
						<Typography sx={styles.caption}>
							Deleting your brainstorm account will permanently
							delete all public and private information associated
							with your profile.
						</Typography>
						<Button
							color="error"
							variant="contained"
							data-testid={`${TEST_ID}__delete-account-button`}
							onClick={deleteAccount}
							sx={styles.button}
						>
							DELETE ACCOUNT
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

export default ProfilePage
