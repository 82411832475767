export const styles = {
	sideBar: {
		display: { xs: 'none', sm: 'block' },
		minWidth: '4px',
		marginRight: '2rem',
		background: 'linear-gradient(180deg, #12BBF182 51%, #12BBF182 100%)',
	},
	responseContainer: {
		backgroundColor: 'rgba(231, 248, 255, 1)',
		padding: '1rem',
	},
	responseText: {
		fontSize: 16,
		color: 'black',
		opacity: 0.87,
	},
	listItem: {
		margin: '10px 0 10px 0',
	},
	mainContainer: {
		flexDirection: 'column',
		gap: '2rem',
		flexGrow: '1',
		paddingY: { xs: '0', sm: '1rem' },
	},
	headerContainer: {
		flexDirection: { xs: 'column', sm: 'row' },
		justifyContent: 'space-between',
		alignItems: { xs: 'flex-start', sm: 'center' },
		gap: '1rem',
	},
	buttonContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		gap: '1rem',
	},
	comparisonSection: {
		width: '100%',
		flexDirection: { xs: 'column', sm: 'row' },
	},
	companyNameContainer: {
		width: { xs: '100%', sm: '20%' },
		minHeight: '8rem',
		paddingRight: { xs: '0', sm: '1rem' },
		paddingTop: { xs: '1rem', sm: '1rem' },
		paddingBottom: { xs: '2rem', sm: '1rem' },
		borderRight: { xs: 0, sm: 1 },
		borderBottom: { xs: 1, sm: 0 },
	},
	companyNameInner: {
		padding: '1rem',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		borderRadius: '4px',
	},
	spinnerContainer: {
		padding: '1rem',
	},
	businessIdeasContainer: {
		width: '100%',
		paddingTop: { xs: '2rem', sm: '1rem' },
		paddingBottom: { xs: '1rem', sm: '1rem' },
		paddingLeft: { xs: '0', sm: '1rem' },
		gap: { xs: '2rem', sm: '1rem' },
		flexDirection: { xs: 'column', sm: 'row' },
	},
	comparisonCard: (isSelected: boolean) => ({
		flexGrow: 1,
		border: 1,
		borderRadius: '4px',
		padding: '1rem',
		width: '100%',
		backgroundColor: isSelected ? '#E7F8FF' : 'white',
	}),
	comparisonText: (isSelected: boolean) => ({
		color: 'black',
		opacity: 0.87,
		fontWeight: isSelected ? 700 : 400,
	}),
	editButtonsContainer: {
		flexDirection: { xs: 'column', sm: 'row' },
		gap: '2rem',
	},
	editPromptContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'end',
	},
	textFieldContainer: {
		'.MuiInputBase-root': {
			height: '100%',
		},
	},
}
