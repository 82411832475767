import React, { useState } from 'react'

import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from '@mui/material'

import { useToastContext } from 'contexts/ToastProvider'

import ButtonWithIconComponent from 'components/ButtonWithIcon'
import GeneratedExampleDropdown from 'components/GeneratedExampleDropdown'
import Section2Title from 'components/Section2Title'

import editIcon from 'assets/images/editIcon.svg'
import refreshIcon from 'assets/images/refreshIcon.svg'

import { GAME_TYPE } from 'enums/GameTypeEnum'
import { ToastSeverity } from 'enums/ToastSeverityEnum'

import { styles } from './Section2.styles'

export const TEST_ID = 'musical-chairs-section2'

export type Props = {
	businessComparisons: string[]
	businessComparisonsHasChanged: boolean[]
	createPrompt: () => void
	crossCompanyName: string
	currentResponses: string[]
	loading: boolean
	reloadIdeas: () => Promise<void>
}

const buildResponseItem = (solution?: string): React.ReactElement => {
	if (!solution) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-response`}
				>
					No response available
				</Typography>
			</Grid>
		)
	}

	const stringSol = solution
		.split(/(\d.)/)
		?.splice(1)
		.filter((x) => isNaN(parseInt(x)))

	if (!stringSol?.length) {
		return (
			<Grid sx={styles.responseContainer}>
				<Typography
					variant="body1"
					sx={{ ...styles.responseText }}
					data-testid={`${TEST_ID}__no-numbered-items`}
				>
					No numbered items found
				</Typography>
			</Grid>
		)
	}

	return (
		<Grid sx={styles.responseContainer}>
			<ol data-testid="gen-ai-response-list">
				{stringSol.map((el: string, i: number) => (
					<Typography
						component="li"
						key={`ai-solution-${i}`}
						variant="body1"
						sx={{ ...styles.responseText, ...styles.listItem }}
					>
						{el}
					</Typography>
				))}
			</ol>
		</Grid>
	)
}

const Section2 = (props: Props) => {
	const { showAlert } = useToastContext()
	const [businessComparisonIndex, setBusinessComparisonIndex] = useState(0)
	const [editPromptMode, setEditPromptMode] = useState(false)
	const [isGenAiLoading, setIsGenAiLoading] = useState(false)

	const onClickBusinessComparison = (index: number) => {
		if (editPromptMode) {
			const editedIdea = document.getElementsByTagName('textarea')
			if (editedIdea && editedIdea.length >= 1) {
				props.businessComparisons[businessComparisonIndex] =
					editedIdea[0].value
			}
		}
		setBusinessComparisonIndex(index)
	}

	const onClickRefresh = () => {
		setBusinessComparisonIndex(0)
		props.createPrompt()
	}

	const onClickEditPrompt = () => {
		setEditPromptMode(true)
	}

	const onClickCancelEditPrompt = () => {
		setEditPromptMode(false)
	}

	const onClickSaveEditPrompt = async () => {
		const lastEditedIdea = document.getElementsByTagName('textarea')
		if (lastEditedIdea && lastEditedIdea.length >= 1) {
			if (
				props.businessComparisons[businessComparisonIndex] !==
				lastEditedIdea[0].value
			) {
				props.businessComparisons[businessComparisonIndex] =
					lastEditedIdea[0].value
				props.businessComparisonsHasChanged[businessComparisonIndex] =
					true
			}
			setEditPromptMode(false)
			await props.reloadIdeas()
			if (showAlert) {
				showAlert({
					objectType: 'editPrompt',
					severity: ToastSeverity.SUCCESS,
				})
			}
		}
	}

	return (
		<>
			<Section2Title />
			<Grid>
				<Grid sx={styles.sideBar} />
				<Grid sx={styles.mainContainer}>
					<Grid sx={styles.headerContainer}>
						<Typography
							data-testid={`${TEST_ID}__inspiration-text`}
						>
							How can you draw cross-industry inspiration from the
							following company?
						</Typography>
						<Grid sx={styles.buttonContainer}>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={refreshIcon}
								text="REFRESH PROMPT"
								data-testid={`${TEST_ID}__refresh-button`}
								onClickAction={onClickRefresh}
							/>
						</Grid>
					</Grid>
					<Grid sx={styles.comparisonSection}>
						<Grid sx={styles.companyNameContainer}>
							<Grid sx={styles.companyNameInner}>
								{props.loading ? (
									<Grid>
										<CircularProgress
											data-testid={`${TEST_ID}__company-spinner`}
											color="secondary"
											sx={styles.spinnerContainer}
										/>
									</Grid>
								) : (
									<Typography
										data-testid={`${TEST_ID}__company-name`}
										alignSelf="center"
									>
										{props.crossCompanyName}
									</Typography>
								)}
							</Grid>
						</Grid>
						<Grid
							id="business-comparison-ideas"
							data-testid="business-comparison-ideas"
							sx={{
								...styles.businessIdeasContainer,
								cursor: props.loading ? 'default' : 'pointer',
							}}
						>
							{props.businessComparisons.map(
								(businessComparison, i) =>
									editPromptMode &&
									businessComparisonIndex === i ? (
										<TextField
											key={`edit-business-comparison-${i}`}
											data-testid={`${TEST_ID}__edit-business-comparison-${i}`}
											defaultValue={businessComparison}
											multiline
											fullWidth
											inputRef={(input) =>
												input && input.focus()
											}
											sx={styles.textFieldContainer}
										/>
									) : (
										<Grid
											key={`business-comparison-${i}`}
											sx={{
												...styles.comparisonCard(
													businessComparisonIndex ===
														i
												),
												cursor: props.loading
													? 'default'
													: 'pointer',
											}}
											onClick={() => {
												if (props.loading) return
												onClickBusinessComparison(i)
											}}
											data-testid={`${TEST_ID}__business-comparison-${i}`}
										>
											{props.loading ? (
												<Grid textAlign="center">
													<CircularProgress
														data-testid={`${TEST_ID}__comparison-spinner-${i}`}
														color="secondary"
														sx={
															styles.spinnerContainer
														}
													/>
												</Grid>
											) : (
												<Grid
													data-testid={`${TEST_ID}__business-comparison-${i}`}
												>
													<Typography
														sx={styles.comparisonText(
															businessComparisonIndex ===
																i
														)}
													>
														{businessComparison}
													</Typography>
												</Grid>
											)}
										</Grid>
									)
							)}
						</Grid>
					</Grid>
					{editPromptMode ? (
						<Grid sx={styles.editButtonsContainer}>
							<Button
								color="secondary"
								variant="contained"
								data-testid={`${TEST_ID}__edit-save-button`}
								onClick={async () => {
									setIsGenAiLoading(true)
									await onClickSaveEditPrompt()
									setIsGenAiLoading(false)
								}}
							>
								<Typography>SAVE CHANGES</Typography>
							</Button>
							<Button
								variant="text"
								color="secondary"
								data-testid={`${TEST_ID}__edit-cancel-button`}
								onClick={onClickCancelEditPrompt}
							>
								<Typography>CANCEL</Typography>
							</Button>
						</Grid>
					) : (
						<Grid sx={styles.editPromptContainer}>
							<ButtonWithIconComponent
								sx={{ padding: 0 }}
								disabled={props.loading}
								iconSvg={editIcon}
								text="EDIT PROMPT"
								data-testid={`${TEST_ID}-edit-prompt`}
								onClickAction={onClickEditPrompt}
							/>
						</Grid>
					)}
					<Typography data-testid={`${TEST_ID}__apply-prompt-text`}>
						Unsure of how to apply the prompt to your innovation
						topic?
					</Typography>
					<GeneratedExampleDropdown
						gameTypeEnum={GAME_TYPE.MUSICAL_CHAIRS}
						headerText={''}
						isLoading={isGenAiLoading}
						customResponse={buildResponseItem(
							props.currentResponses[businessComparisonIndex]
						)}
					/>
				</Grid>
			</Grid>
		</>
	)
}

export default Section2
